<template>
    <div id="chart">
      <apexchart
        type="bar"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </template>
  <script>
  export default {
    name: "propertyColumn",
    data() {
      return {
        series: [
          {
            name: "sales",
            data: [
              {
                x: "VARLIK",
                y: 400,
              },
              {
                x: "İLAN",
                y: 430,
              },
              {
                x: "İHALE",
                y: 448,
              },
             
            ],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 300,
          },
          xaxis: {
            type: "category",
            labels: {
              rotate: 0,
            },
          },
              colors: ["#33b2df", "#546E7A", "#d4526e", "#13d8aa", "#A5978B"],
          
          tooltip: {
            x: {
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      };
    },
  };
  </script>
  <style lang=""></style>
  